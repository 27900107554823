@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.order-page {
    // max-width: 1400px;
    max-width: $maxContentWidth;

    width: 80%;
    height: 100%;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    &__header {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 1.3rem;
        width: 100%;
        margin: 1rem 0;

        &-wrapper {
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 2fr;
            align-items: center;
        }
    }
    &__content {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 20px;
    }

    &__submit {
        background-color: $backgroundGreen;
        border: 0;
        padding: 10px;
        border-radius: 10px;
        color: $backgroundBlack;
        cursor: pointer;
        // height: 35px;
        
        align-self: flex-start;

        &.--disabled {
            cursor: not-allowed;
            filter: grayscale(1);
        }
    }
    &__error {
        font-size: 1rem;
        color: $backgroundGreen;
        align-self: flex-start;
        margin-bottom: 30px;
    }
}

@media (max-width: $mobileWidth) {
    .order-page {
        width: 100%;
        padding: 10px;

        &__submit {
            width: 100%;
            font-size: 1rem;
        }
        &__header {
            font-size: 1.2rem;
            &-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }
}

