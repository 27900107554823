@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.profile {
    display: flex;
    flex-direction: row;
    max-width: $maxContentWidth;
    margin: 0 auto;
    padding: 0 2rem;
    width: 80%;
    margin-top: 3rem;
    gap: 1rem;
    &__nav {
        width: 50%;
        max-width: 500px;
        margin-top: 6rem;
        &-item {
            color: $backgroundGreen;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            padding: .7rem .5rem;
            &.--active {
               font-weight: 600;
            }
        }
    }
    &__content {
        width: 150%;
        &-header {
            color: $backgroundGreen;
            font-size: 2rem;
            padding: 2rem 0;
        }

        &-personal-info {
            background-color: white;
            padding: 1rem;
            border-radius: 10px;
            color: $backgroundGreen;
            h3 {
                padding-bottom: 1rem;
                font-size: 1.5rem;
            }

            &--grid {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-bottom: 2rem;
                &-element {
                    border-bottom: 1px solid $backgroundGreen;
                    padding: 1rem 0;
                }
            }
        }

        &-addresses {
            background-color: white;
            padding: 1rem;
            border-radius: 10px;
            color: $backgroundGreen;

            &--address {
                display: grid;
                grid-template-columns: 2rem auto 2rem;
                img {
                    width: 2rem;
                    height: auto;
                }
            }
        }

        &-order {
            background-color: white;
            padding: 1rem;
            border-radius: 10px;
            width: 100%;
            margin: 2rem 0;
            &:first-of-type {
                margin-top: 0;
            }
            &__header {
                display: flex;
                flex-direction: row;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgb(149, 149, 149);
                .order-header-left {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    grid-template-areas: "number status"
                                         "type type"
                                         "date date";
                    align-items: center;
                    &__number {
                        color: $backgroundGreen;
                        font-weight: 500;
                        grid-area: number;
                    }
                    &__status {
                        grid-area: status;
                        font-size: .6rem;

                       
                    }
                    &__delivery {
                        grid-area: type;
                        font-size: .8rem;
                        font-weight: 500;
                    }
                    &__date {
                        grid-area: date;
                        font-size: .8rem;
                    }
                }
                .order-header-right {
                    width: 100%;
                   
                    .concept-card {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            height: 3rem;
                        }
                        &__info {
                            &--name {
                                font-size: .8rem;
                            }
                            &--address {
                                font-size: .7rem;
                            }
                        }
                    }
                }
            }
            .order-products {
                width: 100%;
                &__product {
                    display: grid;
                    grid-template-columns: 3fr 1fr 1fr;
                    * {
                        font-size: .8rem;
                        font-weight: 500;
                        margin: .5rem 0;
                    }
                    &.--total div {
                        font-size: 1rem;
                        color: $backgroundGreen;
                    }
                }
            }
            .order-rate {
                display: inline-block;
                width: 100%;
                text-align: center;
                color: $backgroundGreen;
                cursor: pointer;

                &-form {
                    display: flex;
                    flex-direction: column;
                    gap: .6rem;
                    align-items: center;
                    label {
                        font-size: .9rem;
                        color: $backgroundGreen;
                    }
                    &__input {
                        resize: none;
                        outline: none;
                        width: 80%;
                        height: 100px;
                        min-width: 300px;
                        border: 1px solid $backgroundGreen;
                        border-radius: 10px;
                        padding: .4rem;
                        font-size: .7rem;
                        &:read-only {
                            border: none;
                            color: $backgroundGreen;
                            text-align: center;
                        }
                    }
                    &__submit {
                        margin: 1rem 0;
                        padding: .5rem;
                        border-radius: 10px;
                        border: none;
                        background-color: $backgroundGreen;
                        color: white;
                    }
                }
            }
        }
    }
    
}



@media (max-width: $mobileWidth) {
    .profile {
        width: 100%;
        flex-direction: column-reverse;
        padding: 0 .5rem;
        margin: 0;
        align-self: flex-start;
        gap: 0;
        h3 {
            display: none;
        }
        &__nav {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            transform: translateY(10px);
            &-item {
                // border: 1px solid black;

                &.--active {
                    background-color: white;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
        }
        &__content {
            width: 100%;
            &-header {
                display: none;
            }
        }
        .concept-card {
            &__img {
                display: none;
            }
        }
        .order-header-right {
            width: 50%;
        }
    }
}