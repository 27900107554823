@import '../../utils/colors.scss';

.not-found {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__img {
        width: 80%;
        max-width: 600px;
        height: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }

    &__msg {
        font-size: 1.2rem;
        text-align: center;
        &--link {
            color: $backgroundGreen;
        }
    }
}