@import "../../utils/sizes.scss";
@import '../../utils/colors.scss';
.delivery {
    width: 80%;
    padding: 3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    
    &__col {
        h3 {
            font-size: 1.75rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            line-height: 1.2;
            color: $backgroundGreen;
        }
        h4 {
            color: $backgroundGreen;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.2;
            padding: .7rem 0;
        }
        p {
            margin-bottom: 1rem;
        }
        ul {
            padding-left: 2rem;
        }
        li::before {
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: $backgroundGreen; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1.5em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
            font-size: 1.2rem;
        }
        li {
            padding: .3rem 0;
        }
        b {
            color: $backgroundGreen;
        }
    }
    &__map {
        // height: fit-content;
        width: 100%;
        min-height: 400px;
        display: grid;
        grid-template-rows: 2.5fr 1fr;
        &-description {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            margin-top: 10px;
            &>div {
                display: grid;
                grid-template-columns: 15px auto;
                gap: 1rem;
                align-items: center;
                &>div {
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}

@media (max-width: $mobileWidth) {
    .delivery {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        width: 100%;
    }
}