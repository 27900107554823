@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.menu-banners {
    // max-height: 10rem;
    max-width: $maxContentWidth;
    width: 80% !important;
    padding: 0 2rem;
    margin: 0 auto;
    img {
        // max-height: 10rem;
        // max-width: 300px;
        max-width: 30vw;
        // width: auto;
        // width: 20rem;
        margin: 0 auto;
        aspect-ratio: 16 / 9 !important;
    }

}
.menu-page {
    // width: 100%;
    // max-width: 1400px;
    max-width: $maxContentWidth;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0 2rem;
    display: grid;
    grid-template-areas: "banner banner"
                        "notice notice"
                        "null categories"
                         "subcat menu";
    grid-template-columns: 10rem auto;
    grid-template-rows: min-content min-content min-content auto;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    // padding: 1% 0;
    
    &.--mobile {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        &__ad {
            width: 100%;
            height: auto;
        }
        &__error-info {
            max-width: 100%;
            width: 100%;
        }
    }
    &__ad {
        width: 100%;
        height: auto;
        // width: auto;
        // height: 50vh;
        margin-bottom: 50px;
    }

    &__error-info {
        max-width: $maxContentWidth;
        width: 100%;
        font-size: 1rem;
        color: black;
        background-color: #CFCFCF;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        border-radius: 8px;
        position: relative;
        grid-area: notice;
        &--close {
            font-size: .8rem;
            position: absolute;
            top: .5rem;
            right: .5rem;
            cursor: pointer;
            width: .7rem;
            height: .7rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

}

@media (max-width: $mobileWidth) {
    .banners-carousel {
        max-height: none;
        max-width: none;
        img {
            max-height: none;
            max-width: none;
            // width: auto;
            // margin: 0 auto;
        }

    }
    .menu-banners  {
        img {
            max-width: none;
        }
    }
    .menu-page {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        &__ad {
            width: 100%;
            height: auto;
        }
        &__error-info {
            max-width: 100%;
            width: 100%;
        }
       
    }
}