@import '../../../utils/colors.scss';

.phone-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    &__input {
        background-color: rgb(200, 200, 200);
        padding: 10px;
        border-radius: 5px;
        font-size: 100%;
        color: $backgroundGreen;
        border: 0;
    }

    &__submit {
        background-color: $backgroundGreen;
        border: 0;
        font-size: 100%;
        border-radius: 5px;
        padding: 10px;
        color: $backgroundBlack;
        cursor: pointer;

        // &:disabled {
        //     filter:grayscale(1)
        // }
    }
    &-error{
        padding-top: 5px;
        color: $backgroundGreen;
        font-size: 100%;
    }
}