@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.cart-page {
    max-width: $maxContentWidth - 100px;
    width: 70%;
    min-width: 800px;
    height: 100%;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .products-carousel {
        width: 100%;
    }

    &__header {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 1.2rem;
        width: 100%;
        margin: 1rem 0;
    }

    &__clear {
        border: 0;
        background-color: $backgroundGreen;
        color: $backgroundBlack;
        padding: .5rem 1rem;
        border-radius: 10px;
        cursor: pointer;
        align-self: flex-end;
        font-size: 1rem;
    }

    &__empty {
        font-style: italic;
        color: $backgroundBlack;
        font-size: 1rem;
    }
    &__total {
        border-top: 1px solid $backgroundGreen;
        padding: 1rem 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            color: $backgroundGreen;
            font-size: 1.2rem;
        }
    }
    &__actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &--submit {
            background-color: $backgroundGreen;
            border: 0;
            padding: .5rem 1rem;
            border-radius: 10px;
            color: $backgroundBlack;
            cursor: pointer;
            // height: 35px;
            font-size: 1rem;

        }
    }
}

@media (max-width: $mobileWidth) {
    .cart-page {
        width: 100%;
        padding: 1rem;
        min-width: 0;
        &__actions {
            flex-direction: column;
            gap: 15px;

            &--submit {
                width: 100%;
            }
        }
    }
}