@import '../../utils/colors.scss';
@import "../../utils/sizes.scss";

.agreement {
    h3, h4 {
        text-align: center;
        font-size: 1.75rem;
        color: $backgroundGreen;
    }
    a {
        color: $backgroundGreen;
    }
    padding: 3rem 5rem;
    b {
        color: $backgroundGreen;
    }
}

@media (max-width: $mobileWidth) {
    .agreement h3, .agreement h4 {
        font-size: 1.5rem;
    }
    .agreement {
        padding: 2rem;
    }
}