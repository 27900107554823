.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: open .5s ease-out;
    // transform: translate(-50%, -45%);
    transform: translate(-50%, -50%);
    z-index: 10000;
    outline: none;
    overflow: auto;
    &__backdrop {
        position: fixed;
        width: 100vw;
        min-height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.8);
        z-index: 9999;
    }
}

@keyframes open {
    from {
        top: -100%;
    }
    to {
        top: 50%;
    }
}