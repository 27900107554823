@import '../../utils/colors.scss';
.categories-carousel {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    margin-top: 1rem;
    // height: 100%;
    position: sticky;
    top: 15px;
    left: 0;
    background-color: $backgroundWhite;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    z-index: 5;
    &::-webkit-scrollbar {
        display: none;
    }
    &__slide {
        width: 100%;
        color: $backgroundGreen;
        font-size: 1rem;
        height: 100%;
        background-color: $backgroundWhite;
        border-radius: 8px;
        margin: 0 5px;
        padding: 10px;
       white-space: nowrap;
        &.--active {
            background-color: $backgroundGreen;
            color: $backgroundWhite;
        }
    }
}