@import '../../utils/colors.scss';

.signin {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        color: $backgroundGreen;
        font-weight: 450;
        font-size: 1.2rem;
        width: 100%;
        margin: 20px 0 10px;
        text-align: center;
    }

    &__desc {
        color: gray;
        font-size: .95rem;
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .phone-input, .code-input {
        justify-content: center;
        color: $backgroundGreen;

    }
    .register-form {
        align-items: center;
        h1, p {
            text-align: center;
        }
    }
}