@import "../../utils/colors.scss";
@import "../../utils/sizes.scss";
.footer {
    width: 100%;
    display: flex;
    flex: 0 1 auto;
    margin: 0 auto;
    flex-direction: column;
    padding: 0 10rem 2rem 10rem;
    gap: 1rem;
    &.--minicart {
        padding-bottom: 80px;
    }
    &__info {
        display: flex;
        flex-direction: row;
        max-width: $maxContentWidth;
        
        width: 100%;
        margin: 0 auto;
        margin-top: 2rem;
        justify-content: space-between;

        .footer__phone, .footer__docs {
            width: 100%;
            display: flex;
            flex-direction: column;
            a {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
        .footer__phone {
            align-items: flex-start;
            justify-content: space-between;
        }
        .footer__docs {
            align-items: flex-end;
            justify-content: space-between;
            a {
                font-size: 1rem;
                font-weight: 400;
                text-align: end;
            }
        }
        .footer__phone-cards {
            display: flex;
            flex-direction: row;
            margin-top: 1rem;
            align-items: center;
            .payment-type {
                width: 3.25rem;
                height: 2rem;
                background-size: 3rem 2rem;
                background-position: 50%;
                background-repeat: no-repeat;

                &--visa {
                    background-size: 60px 47px;
                }
                &--mc {
                    width: 2.5rem;
                    background-size: 85%;
                    margin-top: .25rem;
                }
                &--alfa {
                    width: 4.75rem;
                    background-size: 4rem 2rem;
                }
            }
        }
    }
    &__link, a, p {
        color: $backgroundGreen;
        font-size: 1rem;
    }
    &__socials {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        img {
            width: 2rem;
            height: auto;
        }
    }
}

@media (max-width: $mobileWidth) {
    .footer {
        padding: 5px;
        
        &.--minicart {
            padding-bottom: 60px;
        }
        &__info {
            flex-direction: column;
            gap: 20px;
            .footer__docs, .footer__phone {
                align-items: center;
                a {
                    text-align: center;
                }
            }
        }
    }

}