@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.header {
    background-color: $backgroundGray;
    width: 100%;
    // height: $headerHeight;
    padding: 1rem 0.75rem;
    
    // position: sticky;
    // top: 0;
    // left: 0;
    // z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    &__nav {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $backgroundGreen;
        cursor: pointer;
       
        img {
            width: 1.5rem;
            height: auto;
            margin-right: 5px;
            border-radius: 0;
        }
    }
    &__inner {
        max-width: $maxContentWidth;
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
    }
    &__logo {
        height: 2.7rem;
        width: auto;
        margin-top: auto;
       
    }
    &__city {
        color: $backgroundGreen;
        font-size: 1.2rem;
    }
    &__signin, &__username {
        border-radius: 10px;
        background-color: $backgroundGreen;
        padding: .2rem .5rem;
        color: $backgroundBlack;
        font-weight: 500;
        font-size: 1.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        img {
            width: auto;
            height: 1.2rem;
            margin-right: .3rem;
            border-radius: 0;
        }
    }
}

.delivery-address-search__dropdown--back {
    position: fixed;
    overflow: hidden;
    display: none;
    width: 1000vw;
    height: 100%;
    top: 0%;
    left: -50%;
    z-index: 999;
    background-color: black;
    opacity: 0.5;

    &.open {
        display: block;
    }
}

@media (max-width: $mobileWidth) {
    .header {
        width: 100%;

        position: relative;

        &__inner {
            width: 100%;
            padding: 0;
        }
        &__nav {
            span {
                display: none;
            }
        }
    }
    .delivery-address-search {
        display: none;
    }
}