@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.modal-order-address {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 430px;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    &--close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.5rem;
        border: 0;
        background-color: inherit;
        color: $backgroundGreen;
        font-weight: 600;
    }
    &__delivery-check {
        font-size: .9rem;
        color: $backgroundGreen;
    }
    &-grid {
        display: grid;
        grid-template-columns: 300px 300px;
        gap: 1rem;
    }
    &--submit {
        width: 100%;
        color: white;
        background-color: $backgroundGreen;
        border-radius: 20px;
        padding: 5px 0;
        margin: 10px 0;
        &:disabled {
            filter: grayscale(.7);
            cursor: not-allowed;
        }
    }
    .suggest-address {
        margin-bottom: .25rem;
        font-size: 1rem;
        cursor: pointer;
        color: $backgroundGreen;
        border-bottom: 1px dashed $backgroundGreen;

        &:hover {
            font-weight: 700;
        }
    }
    &__header {
        font-size: 1rem;
        color: $backgroundGreen;
        font-weight: 600;
    }
    &__inputs {
        display: grid;
        grid-template-areas: "street street"
                             "home flat"
                             "floor entrance";
        gap: 10px;
        margin-top: 10px;
        &-input {
            border-radius: 8px;
            border: 0;
            padding: 10px;
            width: 100%;
            background-color: $backgroundGray;
            color: $backgroundGreen;
            &.--street {
                grid-area: street;
                font-size: 1rem;
            }
            &.--home {
                grid-area: home;
                font-size: 1rem;
            }
            &.--flat {
                grid-area: flat;
                font-size: 1rem;
            }
            &.--entrance {
                grid-area: entrance;
                font-size: 1rem;
            }
            &.--floor {
                grid-area: floor;
                font-size: 1rem;
            }
            &-streets-list {
                grid-area: list;
                overflow-y: scroll;
                max-height: 400px;
            }
        }
    }
}

@media (max-width: $mobileWidth) {
    .modal-order-address {
        height: auto;
        width: 90vw;
        &-grid {
            grid-template-columns: 1fr;
        }
    }
}